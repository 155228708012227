import { Typography, Card } from "@material-tailwind/react";

function StatCard({ count, title }) {
  return (
    <Card className='text-white' color="transparent" shadow={false}>
      <Typography
        variant="lead"
        className="text-3xl font-bold"
      >
        {count}
      </Typography>
      <hr className="mt-2 mb-4 " />
      <Typography
        variant="h3"
        className="mt-1 text-xl font-normal"
      >
        {title}
      </Typography>
    </Card>
  );
}

const stats = [
  {
    count: "5+",
    title: "Years Experienced",
  },
  {
    count: "1000+",
    title: "Custom Pages Designed",
  },
  {
    count: "1000+",
    title: "PSD to HTML",
  },

];

export function StatSection() {
  return (
    <section className="py-12 px-8 mx-auto text-center bg-theme-color-1">
      <div className="">
          <div className="grid lg:grid-cols-3 gap-10 gap-x-20">
            {stats.map((props, key) => (
              <StatCard key={key} {...props} />
            ))}
          </div>
        </div>
    </section>
  );
}

export default StatSection;