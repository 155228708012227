import { Typography, Card, List, ListItem } from "@material-tailwind/react";
import { PaintBrushIcon, CircleStackIcon, CodeBracketIcon, CubeIcon } from "@heroicons/react/24/outline";
function SkillsetCard({ count, skills, Icon }) {
  return (
    <Card color="transparent" shadow={false}>
      <div className="flex">
        <Icon className='w-6 mr-2' />
        <Typography
          variant="h3"
          className="text-base font-bold"
          color="blue-gray"
        >
          {count}
        </Typography>
      </div>

      <hr className="mt-2 mb-4 max-w-sm" />
      <List className="min-w-full">

        {skills.map((props, key) => (
          <ListItem key={key} className="py-1" >
            <Typography variant="small">
              {props}
            </Typography>
          </ListItem>

        ))}
      </List>

    </Card>
  );
}


const stats = [
  {
    count: "Web Design",
    skills: ["UI/UX Design", 'Responsive Design'],
    Icon: PaintBrushIcon,
  },
  {
    count: "Frontend",
    skills: ["Javascript", 'ReactJS', 'NextJS', 'TailwindCSS'],
    Icon: CodeBracketIcon,
  },
  {
    count: "Backend",
    skills: ["NodeJS", 'PHP'],
    Icon: CubeIcon,
  },
  {
    count: "Data Analysis",
    skills: ["Google Analytics 4", 'SEMRush'],
    Icon: CircleStackIcon,
  },
];

export function Skillset() {
  return (
    <section className="lg:py-28 py-10 px-8 container mx-auto">
      <div className="">
        <div>
          <div className="grid grid-cols-2 gap-10 gap-x-20">
            {stats.map((props, key) => (
              <SkillsetCard key={key} {...props} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skillset;