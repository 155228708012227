import { Typography } from "@material-tailwind/react";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="w-full bg-theme-color-1 text-white p-8">
      <div className="flex flex-wrap items-center justify-center gap-6 md:justify-between">
        <img src="/logo512.png" alt="kinleunghk.com-logo" className="w-20" />
        <ul className="flex gap-8">
          {['Experience', 'Achievement', 'Project', 'Contact'].map((item, index) => (
            <li key={index}>
              <Typography
                as="a"
                href={`#${item.toLowerCase()}`}
                variant="small"
                className="font-normal transition-colors hover:text-theme-color-2"
              >
                {item}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
      <hr className="my-4 border-blue-gray-50" />
      <Typography className="text-center font-normal">
        &copy; {currentYear} Kin Leung
      </Typography>
    </footer>
  );
}