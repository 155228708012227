import { Button, Typography } from "@material-tailwind/react";
import portfolioIcon from "../images/portfolio.webp";

export function HeroSection() {
  return (
    <section className="bg-no-repeat lg:bg-contain bg-cover bg-right grid grid-cols-1 md:grid-cols-2 shadow-md pb-2">
      <div className="text-center my-auto">
        <Typography variant="lead" className="text-3xl" color="blue-gray">
          HEY, I'M
        </Typography>
        <Typography
          variant="h1"
          className="mt-2 mb-6  font-bold"
        >
          KIN LEUNG
        </Typography>
        <Typography
          variant="h2"
          className="mt-2 mb-6  font-bold"
        >
          A WEB DEVELOPER & SEO EXPERT
        </Typography>
        <div className=" space-x-4">
          <a href="#contact">
            <Button variant="outlined" className="flex-shrink-0 bg-theme-color-1 text-white">
              Get In Touch
            </Button>
          </a>
          <a href="#project">
            <Button variant="outlined" className="flex-shrink-0 bg-theme-color-1 text-white">
              Browse Projects
            </Button>
          </a>
        </div>

      </div>
      <div className="">
        <img src={portfolioIcon} className="mx-auto" alt="portfolio" />
      </div>
    </section>
  );
}
export default HeroSection;