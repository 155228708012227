import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
  Typography,
  Avatar,
  List,
  ListItem,
} from "@material-tailwind/react";
import etnetLogo from "../images/etnet-logo.webp";
import ddmLogo from "../images/ddm-logo.webp";
import iasialogo from "../images/iasia-logo.webp";
export default function TimelineWithAvatar() {

  return (
    <div className="mx-auto md:mx-0 px-8">
      <Timeline>
        <TimelineItem>
          <TimelineConnector />
          <TimelineHeader>
            <TimelineIcon className="p-0">
              <Avatar size="md" src={etnetLogo} alt="etnet-logo" />
            </TimelineIcon>
            <div>
              <Typography variant="h3" color="blue-gray" className="text-base">
                Jun 2019 - Current
              </Typography>
              <Typography variant="h3" color="blue-gray" className="text-lg">
                ET Net Limited
              </Typography>
            </div>

          </TimelineHeader>
          <TimelineBody className="pb-8">
            <List>
              <ListItem className="p-1"><Typography variant="small">Frontend and backend development responsibilities</Typography>
              </ListItem>
              <ListItem className="p-1"><Typography variant="small">Experience with online payment systems</Typography>
              </ListItem>
              <ListItem className="p-1"><Typography variant="small">SEO optimization expertise</Typography></ListItem>
            </List>
          </TimelineBody>
        </TimelineItem>
        <TimelineItem>
          <TimelineConnector />
          <TimelineHeader>
            <TimelineIcon className="p-0">
              <Avatar size="md" src={ddmLogo} alt="ddm-logo" />
            </TimelineIcon>
            <div>
              <Typography variant="h3" color="blue-gray" className="text-base">
                Oct 2018 - Dec 2018
              </Typography>
              <Typography variant="h3" color="blue-gray" className="text-lg">
                Diamond Digital Marketing (HK) Ltd
              </Typography>
            </div>
          </TimelineHeader>
          <TimelineBody className="pb-8">
            <List>
              <ListItem className="p-1"><Typography variant="small">Managed Facebook ads campaigns</Typography>
              </ListItem>
              <ListItem className="p-1"><Typography variant="small">Managed Google ads campaigns</Typography>
              </ListItem>
              <ListItem className="p-1"><Typography variant="small">Implemented backlink building strategies</Typography>
              </ListItem>
              <ListItem className="p-1"><Typography variant="small">Conducted SEMrush analysis for SEO optimization</Typography>
              </ListItem>
            </List>
          </TimelineBody>
        </TimelineItem>
        <TimelineItem>
          <TimelineHeader>
            <TimelineIcon className="p-0">
              <Avatar size="md" src={iasialogo} alt="iasia-logo" />
            </TimelineIcon>
            <div>
            <Typography variant="h3" color="blue-gray" className="text-base">
            May 2018 - Aug 2018
              </Typography>
              <Typography variant="h3" color="blue-gray" className="text-lg">
                iAsia Online Systems Limited
              </Typography>
            </div>

          </TimelineHeader>
          <TimelineBody>
            <List>
              <ListItem className="p-1"><Typography variant="small">Worked on DSB online trading website</Typography>
              </ListItem>
              <ListItem className="p-1"><Typography variant="small">Contributed to system enhancements and bug fixing</Typography>
              </ListItem>
              <ListItem className="p-1"><Typography variant="small">Utilized Crystal Reports for client and agent reporting</Typography></ListItem>
            </List>
          </TimelineBody>
        </TimelineItem>
      </Timeline>
    </div>
  );
}