import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import HeroSection from '../components/HeroSection'
import ProjectSection from '../components/ProjectSection'
import TimelineWithAvatar from '../components/TimelineWithAvatar'
import SkillsetSection from '../components/SkillsetSection'
import StatSection from '../components/StatSection'
import ContactSection from '../components/ContactSection'
import AchievementSection from '../components/AchievementSection'
export default function Home() {
  return (
    <main className="">
      <section className="my-4">
        <HeroSection />
      </section>
      <div className="container mx-auto">
        <section id='experience' className="mt-12 text-center text-3xl">
          <Typography className="" variant='h2'>My Experiences & Skills</Typography>
        </section>
        <section className="my-12 grid grid-cols-1 md:grid-cols-2">
          <TimelineWithAvatar />
          <SkillsetSection />
        </section>
      </div>
      <section className="my-4">
        <StatSection />
      </section>
      <div className="container mx-auto">
        <section id='achievement' className="my-4">
          <AchievementSection />
        </section>
        <section id='project' className="my-4">
          <Typography variant='h2' className="text-3xl text-center mb-6">My Projects</Typography>
          <ProjectSection />
        </section>
        <section id='contact' className="my-4">
          <ContactSection />
        </section>
      </div>
    </main>
  );
}
