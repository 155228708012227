import { Carousel } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import yifunghkThumbnail from '../images/yifunghk.webp'
import amoArtinclusionThumbnail from '../images/amo-artinclusion.webp'
import smartlivingThumbnail from '../images/smartliving.webp'
import fintechThumbnail from '../images/fintech.webp'
import whexpoThumbnail from '../images/whexpo.webp'
import chilokhkThumbnail from '../images/chilokhk.webp'
import tutorplushkThumbnail from '../images/tutorplushk.webp'
import shenpingThumbnail from '../images/shenping.webp'
import patataThumbnail from '../images/patata.webp'

export default function ProjectSection() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize();

    const resizeListener = () => {
      handleResize();
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const images = [
    [
      { src: amoArtinclusionThumbnail, alt: "amo-artinclusion", landing: 'https://amo-artinclusion.etnet.com.hk' },
      { src: smartlivingThumbnail, alt: "smartliving", landing: 'https://smartcityslpa.etnet.com.hk' },
      { src: shenpingThumbnail, alt: "shenping", landing: 'https://shenping.etnet.com.hk' },
    ],
    [
      { src: whexpoThumbnail, alt: "whexpo", landing: 'https://whexpo.etnet.com.hk' },
      { src: fintechThumbnail, alt: "fintech", landing: 'https://fintech.etnet.com.hk' },
      { src: tutorplushkThumbnail, alt: "tutorplushk", landing: 'https://www.tutorplushk.com' },
    ],
    [
      { src: yifunghkThumbnail, alt: "yifunghk", landing: 'https://www.yifunghk.com' },
      { src: patataThumbnail, alt: "patata", landing: 'https://www.patata.com.hk' },
      { src: chilokhkThumbnail, alt: "chilokhk", landing: 'https://www.chilokhk.com' }

    ]
  ];

  return (
    <section className="bg-theme-color-1 p-4">
      <Carousel loop={true} autoplay={true} className="rounded-xl">
        {isMobile ? (
          images.flat().map((image, index) => (
            <a href={image.landing} key={`image${index}`}>
              <img
                src={image.src}
                alt={image.alt}
                className="h-full w-full object-cover object-center"
              />
            </a>
          ))
        ) : (
          images.map((imageGroup, groupIndex) => (
            <div key={`group${groupIndex}`} className="grid grid-cols-3 gap-x-8 h-full">
              {imageGroup.map((image, index) => (
                <a target="_blank" href={image.landing} key={`image${groupIndex}-${index}`}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="h-full w-full object-cover object-center"
                  />
                </a>
              ))}
            </div>
          ))
        )}
      </Carousel>
    </section>
  );
}